import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProjectCard from "../../components/ProjectCard"
import FeaturedCaseStudiesGrid from "../../components/FeaturedCaseStudiesGrid"
import ogImage from "../../images/Frame-7.png"

const Projects = () => {
  const pageQuery = useStaticQuery(graphql`
    {
      banner_image: file(
        relativePath: { eq: "project-images/redsea-project.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allContentfulProjects(sort: { order: DESC, fields: createdAt }) {
        nodes {
          id
          slug
          client
          projectname
          projectDescription {
            projectDescription
          }
          projectBanner {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
          technology
        }
      }
      featuredProjects: allContentfulProjects(
        filter: { featuredProject: { eq: "Yes" } }
        limit: 3
        sort: { order: DESC, fields: updatedAt }
      ) {
        nodes {
          id
          slug
          client
          projectname
          technology
          thumbnailTheme
          thumbnailBackground
          projectDescription {
            projectDescription
          }
          projectThumbnail {
            title
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  `)

  const featuredProjects = pageQuery.featuredProjects.nodes
  const featuredProjectIds = pageQuery.featuredProjects.nodes.map(p => p.id)
  const projects = pageQuery.allContentfulProjects.nodes.filter(
    c => !featuredProjectIds.includes(c.id)
  )

  return (
    <Layout>
      <SEO
        title="Projects | Shopify Plus Agency"
        image={`https://www.coderapper.com${ogImage}`}
      />
      <main className="projects-page-wrapper">
        {featuredProjects.length > 0 && (
          <section className="featured-projects">
            <div className="container">
              <FeaturedCaseStudiesGrid gridData={featuredProjects} />
            </div>
          </section>
        )}
        <section className="projects-cards">
          <div className="container">
            {projects.map(c => (
              <ProjectCard key={c.id} cardData={c} />
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Projects
