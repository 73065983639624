import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CrButton from "./arrowButton"

const FeaturedCaseStudiesCard = ({ cardData }) => {
  return (
    <div
      className={`featured-card ${cardData.thumbnailTheme}`}
      style={{
        backgroundColor: cardData.thumbnailBackground.includes("#")
          ? `${cardData.thumbnailBackground}`
          : `#${cardData.thumbnailBackground}`,
      }}
    >
      <div className="featured-card__content">
        <h1
          className="featured-card__content-title"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          {cardData.projectDescription.projectDescription}
        </h1>
        <div
          className="featured-card__content-platform"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          {cardData.technology.map((t, i, arr) =>
            arr.length - 1 === i ? (
              <span key={`tech_${i}`}>{t}</span>
            ) : (
              <span key={`tech_${i}`}>{t} |</span>
            )
          )}
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          <CrButton
            label="Read More"
            path={cardData.slug}
            type={cardData.thumbnailTheme.toLowerCase()}
          />
        </div>
      </div>
      <div
        className="featured-card__thumbnail"
        data-sal="fade"
        data-sal-delay="50"
        data-sal-easing="ease"
        data-sal-duration="800"
      >
        <GatsbyImage
          image={getImage(cardData.projectThumbnail)}
          alt={cardData.projectThumbnail.title}
        />
      </div>
    </div>
  )
}

export default FeaturedCaseStudiesCard
