import React from "react"
import FeaturedCaseStudiesCard from "./FeaturedCaseStudiesCard"

const FeaturedCaseStudiesGrid = ({ gridData }) => {
  return (
    <div>
      {gridData.length > 0 && (
        <div
          className={
            gridData.length > 2
              ? "featured-projects__grid grid-masonry"
              : "featured-projects__grid"
          }
        >
          {gridData.map(c => (
            <FeaturedCaseStudiesCard key={c.id} cardData={c} />
          ))}
        </div>
      )}
    </div>
  )
}

export default FeaturedCaseStudiesGrid
