import React from "react"
import CrButton from "./arrowButton"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectCard = ({ cardData }) => {
  return (
    <div className="project-card">
      <div className="project-card__content">
        <h2
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="800"
          className="project-card__content-title"
        >
          {cardData.projectname}
        </h2>
        <p
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="800"
          className="project-card__content-description"
        >
          {cardData.projectDescription.projectDescription}
        </p>
        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="800"
          className="project-card__content-platform"
        >
          <span className="tech-title">PLATFORM:</span>
          {cardData.technology.map((t, i) => (
            <span key={i}>{t}</span>
          ))}
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          <CrButton label="Read More" path={cardData.slug} type="light" />
        </div>
      </div>
      <div
        className="project-card__thumbnail"
        data-sal="fade"
        data-sal-delay="100"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <GatsbyImage
          image={getImage(cardData.projectBanner)}
          alt={cardData.projectBanner.title}
        />
      </div>
    </div>
  )
}

export default ProjectCard
